var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.KeyStore = void 0;
/**
 * KeyStores are passed to {@link "@near-js/wallet-account".near.Near | Near} via {@link "@near-js/wallet-account".near.NearConfig | NearConfig}
 * and are used by the {@link "@near-js/signers".in_memory_signer.InMemorySigner | InMemorySigner} to sign transactions.
 *
 */
class KeyStore {}
exports.KeyStore = KeyStore;
export default exports;